<template>
  <div>
    <ShiftsHistoryTable />
  </div>
</template>
<script>
import ShiftsHistoryTable from "@candidate/ShiftsHistoryTable";

export default {
  components: {
    ShiftsHistoryTable,
  },
};
</script>
